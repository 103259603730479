import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

export const useGetMatter = ({ matterId }: { matterId: string | null }) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery([QueryEntity.Matter, { matterId }], async () => {
    return fetch(`${APIBaseChronos}/api/matters/${matterId}`, fetchConfigGET)
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch matter');
        }
        return res.json();
      })
      .catch((err) => {
        console.error('Fetch Error: ', err);
        toast.error('There was an error while fetching matter. Please try again later.');
      });
  });
};
