import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

const useFetchOrgUsers = (orgIds: string[] = []) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery(
    'fetchOrgUsers',
    () => {
      const queryParams = new URLSearchParams({ orgIds: orgIds.join(',') }).toString();
      return fetch(`${APIBaseChronos}/api/org/users?${queryParams}`, fetchConfigGET)
        .then((res) => res.json())
        .catch((err) => {
          console.error(err);
        });
    },
    {
      cacheTime: 0,
      enabled: true,
    },
  );
};

export default useFetchOrgUsers;
