import { useEffect, useState } from 'react';

import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { useSearchParams, Route, Routes } from 'react-router-dom';
import { ChronosChronology } from 'types';

import ChronologyEditor from './ChronologyEditor';
import ChronologyList from './ChronologyList';

const ChronologyViewer = () => {
  // State
  const [chronologies, setChronologies] = useState<ChronosChronology[]>([]);
  const [chronologiesSearchFilter, setChronologiesSearchFilter] = useState('');
  const [searchParams] = useSearchParams();

  const caseId = searchParams.get('caseId');

  // Hooks
  const { fetchConfigGET } = useGetFetchConfig();

  const {
    data: responseChronologies,
    isFetching: isLoadingChronologies,
    refetch: refetchCaseChronologies,
  } = useQuery(
    ['userChronologies', caseId],
    () => {
      const cleanSearchText = chronologiesSearchFilter?.replace(/\s+/g, ' ').trim() || '';
      return fetch(`${APIBaseChronos}/api/chronology/?caseId=${caseId}&search=${cleanSearchText}`, fetchConfigGET).then(
        (res) => {
          return res.json();
        },
      );
    },
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  // Effects
  useEffect(() => {
    refetchCaseChronologies();
  }, [refetchCaseChronologies]);

  useEffect(() => {
    if (responseChronologies?.chronologies) {
      setChronologies(responseChronologies?.chronologies);
    }
  }, [responseChronologies]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ChronologyList
            caseId={caseId}
            chronologies={chronologies}
            isLoadingChronologies={isLoadingChronologies}
            refetchCaseChronologies={refetchCaseChronologies}
            setChronologiesSearchFilter={setChronologiesSearchFilter}
            chronologiesSearchFilter={chronologiesSearchFilter}
          />
        }
      />
      <Route path="view" element={<ChronologyEditor />} />
    </Routes>
  );
};

export default ChronologyViewer;
