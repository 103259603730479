import { useCallback, useEffect, useRef, useState } from 'react';

import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import PDFViewer from 'components/organisms/PDFViewer';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { Coordinate } from 'types';

interface HighlightCoordinate {
  factId: string;
  height: number;
  left: number;
  pageIndex: number;
  top: number;
  width: number;
}

const DocViewer = ({ docId, coordinates }: { docId: string; coordinates: Coordinate[] }) => {
  // State
  const navigate = useNavigate();
  const location = useLocation();

  const viewerRef = useRef(null);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [loadingFile, setLoadingFile] = useState(true);
  const [minPage, setMinPage] = useState(1);
  const [highlightCoordinates, setHighlightCoordinates] = useState<HighlightCoordinate[]>([]);
  const { fetchConfigGET } = useGetFetchConfig();

  // Data fetching
  const getDocumentUrl = useCallback(async () => {
    if (!docId) return null; // Early exit if no docId provided

    try {
      const downloadResult = await fetch(`${APIBaseChronos}/api/docs/${docId}`, fetchConfigGET);
      if (!downloadResult.ok) throw new Error('Download failed');

      const blob = await downloadResult.blob();
      const fileBlobUrl = URL.createObjectURL(blob);
      // Consider where and how to call URL.revokeObjectURL(fileBlobUrl) for cleanup
      return fileBlobUrl;
    } catch (error) {
      console.error(error);
      return null; // Consider a structured error handling approach
    }
  }, [docId, fetchConfigGET]);

  const { refetch: fetchDocument } = useQuery(['downloadDoc', docId], getDocumentUrl, { enabled: false });

  // Event handlers
  const handleCloseDocDetailView = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('chunkId');
    searchParams.delete('docId');
    navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
  }, [navigate, location.pathname, location.search]);

  // Effects
  useEffect(() => {
    if (docId) {
      setLoadingFile(true);
      fetchDocument().then(({ data: documentUrl }) => {
        if (documentUrl && typeof documentUrl === 'string') {
          setFileUrl(documentUrl);
        }
        setLoadingFile(false);
      });
    }
    // eslint-disable-next-line
  }, [docId]);

  useEffect(() => {
    if (coordinates && coordinates.length > 0) {
      setMinPage(coordinates[0].n_page);
      setHighlightCoordinates(
        coordinates.map((coordinate: Coordinate) => {
          return {
            factId: coordinate.fact_id,
            height: coordinate.height_c * 100,
            left: coordinate.left_c * 100,
            pageIndex: coordinate.n_page - 1,
            top: coordinate.top_c * 100,
            width: coordinate.width_c * 100,
          };
        }) || [],
      );
    }
  }, [coordinates]);

  return (
    <div className="flex h-full flex-col">
      <div className="flex-grow overflow-hidden">
        {fileUrl === 'download-error' ? (
          'File cannot be displayed, please try again later.'
        ) : (
          <div ref={viewerRef} className="h-full">
            <PDFViewer
              fileUrl={fileUrl}
              loading={loadingFile}
              initialPage={minPage}
              highlights={highlightCoordinates}
              handleClosePDFViewer={handleCloseDocDetailView}
              closeButtonText="Close"
              defaultZoom={1}
              type="inline"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DocViewer;
