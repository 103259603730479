import { useEffect, useState } from 'react';

import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { StageSpinner } from 'react-spinners-kit';

type StatusKey = 'green' | 'amber' | 'red' | 'unknown';

const STATUS_MESSAGES: Record<StatusKey, string> = {
  green: 'Short processing times',
  amber: 'Moderate processing times',
  red: 'Longer processing times',
  unknown: '',
};

const COLORS: Record<StatusKey, string> = {
  green: '#22c55e',
  amber: '#E3B336',
  red: '#E33636',
  unknown: '#4161FF',
};

const StatusIndictor = () => {
  const [status, setStatus] = useState<StatusKey>('unknown');
  const { fetchConfigGET } = useGetFetchConfig();

  const {
    isFetching: isLoadingStatus,
    data: responseStatus,
    refetch: refetchStatus,
  } = useQuery(
    [],
    () => {
      return fetch(`${APIBaseChronos}/api/system`, fetchConfigGET)
        .then((res) => {
          return res.json();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  useEffect(() => {
    if (responseStatus?.status) {
      setStatus(responseStatus.status);
    }
  }, [responseStatus]);

  useEffect(() => {
    refetchStatus();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="flex min-w-12 flex-col rounded-lg border border-solid bg-white px-4 py-2 text-black"
      style={{
        borderColor: COLORS[status],
      }}
    >
      <div className="mb-1 text-xs">Service information</div>
      <div className="flex flex-row items-center text-base">
        {isLoadingStatus ? (
          <div className="flex w-full flex-row items-center justify-center">
            <StageSpinner className="m-auto" size={25} color={COLORS[status]} />
          </div>
        ) : (
          <p className="text-sm">
            <FontAwesomeIcon icon={faClock} style={{ color: COLORS[status] }} className="mr-3" />
            {STATUS_MESSAGES[status]}
          </p>
        )}
      </div>
    </div>
  );
};

export default StatusIndictor;
