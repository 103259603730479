import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery, UseQueryResult } from 'react-query';

export const docsQueryKey = (caseId: string | null, page: number, pageSize: number, searchQuery: string) => [
  'userDocs',
  caseId,
  page,
  pageSize,
  searchQuery,
];

function useFetchDocs(
  caseId: string | null,
  page: number,
  pageSize: number,
  searchQuery: string,
): UseQueryResult<any, unknown> {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<any, unknown>(
    docsQueryKey(caseId, page, pageSize, searchQuery),
    () =>
      fetch(
        `${APIBaseChronos}/api/case/${caseId}/docs?page=${page}&pageSize=${pageSize}&searchQuery=${searchQuery}`,
        fetchConfigGET,
      ).then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      }),
    { refetchOnWindowFocus: false },
  );
}

export default useFetchDocs;
