import { CSSProperties } from 'react';

import { StylesConfig } from 'react-select';
import { MyOptionType } from 'types';

export const MARGIN_TOP = 80;
export const LINE_HEIGHT = 24;

export const reactSelectStyles: StylesConfig<MyOptionType, false> = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: '#F3F3F3',
    outline: 'none',
    border: 'none',
    borderColor: 'transparent',
    boxShadow: 'none',
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isFocused ? '#081D57' : '#7A7A7C',
    backgroundColor: state.isFocused ? '#F3F3F3' : '#FFFFFF',
    outline: 'none',
    width: 'auto',
    marginBottom: '10px',
    borderRadius: '9px',
    cursor: 'pointer',
  }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
    padding: '10px 16px 6px 16px',
    border: 'none',
  }),
  menuPortal: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: '#081D57',
    fontWeight: 700,
    outline: 'none',
  }),
};

export const reactSelectStylesCaseCreator: StylesConfig<MyOptionType, false> = {
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isFocused ? '#000000' : '#000000',
    backgroundColor: state.isFocused ? '#F3F3F3' : '#FFFFFF',
    outline: 'none',
    width: 'auto',
    marginBottom: '10px',
    cursor: 'pointer',
  }),
};

export const reactSelectStylesMatterCreator: StylesConfig<MyOptionType, false> = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: '#F3F3F3',
    outline: 'none',
    borderColor: 'transparent',
    boxShadow: 'none',
    height: '66px',
    marginTop: '16px',
    border: '1px solid var(--black-10, #E7E7E7)',
    borderRadius: '16px',
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isFocused ? '#000000' : '#000000',
    backgroundColor: state.isFocused ? '#F3F3F3' : '#FFFFFF',
    outline: 'none',
    width: 'auto',
    marginBottom: '10px',
    borderRadius: '9px',
    cursor: 'pointer',
  }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
    padding: '10px 16px 6px 16px',
    border: 'none',
  }),
  menuPortal: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: '#000000',
    fontWeight: 500,
    outline: 'none',
  }),
};

export const reactSelectStylesExplorerMulti: StylesConfig<MyOptionType, true> = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: '#FFFFFF',
    outline: 'none',
    border: 'none',
    borderColor: 'transparent',
    boxShadow: 'none',
    miHeight: '48px',
    maxHeight: '96px',
    borderRadius: '6px',
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
    borderRadius: '6px',
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    maxHeight: '96px',
    overflowY: 'scroll',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '::-webkit-scrollbar-track': {
      display: 'none',
    },
    '::-webkit-scrollbar-thumb': {
      display: 'none',
    },
    '::-webkit-scrollbar-thumb:hover': {
      display: 'none',
    },
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isFocused ? '#081D57' : '#7A7A7C',
    backgroundColor: state.isFocused ? '#F3F3F3' : '#FFFFFF',
    outline: 'none',
    width: 'auto',
    marginBottom: '10px',
    borderRadius: '9px',
    cursor: 'pointer',
  }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
    padding: '10px 16px 6px 16px',
    border: 'none',
  }),
  menuPortal: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: '#081D57',
    fontWeight: 700,
    outline: 'none',
  }),
};

export const reactSelectStylesExplorer: StylesConfig<MyOptionType, false> = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: '#FFFFFF',
    outline: 'none',
    border: 'none',
    borderColor: 'transparent',
    boxShadow: 'none',
    height: '48px',
    borderRadius: '6px',
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
    borderRadius: '6px',
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isFocused ? '#081D57' : '#7A7A7C',
    backgroundColor: state.isFocused ? '#F3F3F3' : '#FFFFFF',
    outline: 'none',
    width: 'auto',
    marginBottom: '10px',
    borderRadius: '9px',
    cursor: 'pointer',
  }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
    padding: '10px 16px 6px 16px',
    border: 'none',
  }),
  menuPortal: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: '#081D57',
    fontWeight: 700,
    outline: 'none',
  }),
};

export const reactSelectStylesExplorerRecent: StylesConfig<MyOptionType, false> = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: '#FFFFFF',
    outline: 'none',
    border: 'none',
    borderColor: 'transparent',
    boxShadow: 'none',
    height: '60px',
    borderRadius: '6px',
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
    borderRadius: '6px',
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isFocused ? '#081D57' : '#7A7A7C',
    backgroundColor: state.isFocused ? '#F3F3F3' : '#FFFFFF',
    outline: 'none',
    width: 'auto',
    marginBottom: '10px',
    borderRadius: '9px',
    cursor: 'pointer',
  }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
    padding: '10px 16px 6px 16px',
    border: 'none',
  }),
  menuPortal: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: '#081D57',
    fontWeight: 700,
    outline: 'none',
  }),
};

export const reactSelectStylesTableGenerator = (color: string): StylesConfig<MyOptionType, false> => {
  return {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: 'white',
      border: '1px solid #E5E5E5',
      outline: 'none',
      borderColor: 'transparent',
      boxShadow: 'none',
    }),
    input: (baseStyles, state) => ({
      ...baseStyles,
      outline: 'none',
    }),
    container: (baseStyles, state) => ({
      ...baseStyles,
      outline: 'none',
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      color: state.isFocused ? '#081D57' : '#7A7A7C',
      backgroundColor: state.isFocused ? '#F3F3F3' : '#FFFFFF',
      outline: 'none',
      width: 'auto',
      borderRadius: '9px',
      cursor: 'pointer',
    }),
    menu: (baseStyles, state) => ({
      ...baseStyles,
      outline: 'none',
      border: 'none',
    }),
    menuPortal: (baseStyles, state) => ({
      ...baseStyles,
      outline: 'none',
    }),
    singleValue: (baseStyles, state) => ({
      ...baseStyles,
      color: color,
      fontWeight: 700,
      outline: 'none',
    }),
  };
};

export const reactSelectStylesTable: StylesConfig<MyOptionType, false> = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: 'white',
    border: '1px solid #E5E5E5',
    outline: 'none',
    borderColor: 'transparent',
    boxShadow: 'none',
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isFocused ? '#081D57' : '#7A7A7C',
    backgroundColor: state.isFocused ? '#F3F3F3' : '#FFFFFF',
    outline: 'none',
    width: 'auto',
    borderRadius: '9px',
    cursor: 'pointer',
  }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
    border: 'none',
  }),
  menuPortal: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: '#081D57',
    fontWeight: 700,
    outline: 'none',
  }),
};

export const reactSelectStylesFormFact: StylesConfig<MyOptionType, false> = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: 'white',
    border: '1px solid #E5E5E5',
    outline: 'none',
    borderColor: 'transparent',
    boxShadow: 'none',
    height: '48px',
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isFocused ? '#081D57' : '#7A7A7C',
    backgroundColor: state.isFocused ? '#F3F3F3' : '#FFFFFF',
    outline: 'none',
    width: 'auto',
    borderRadius: '9px',
    cursor: 'pointer',
  }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
    border: 'none',
  }),
  menuPortal: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: '#081D57',
    fontWeight: 700,
    outline: 'none',
  }),
};

export const reactSelectStylesDownloadColumns: StylesConfig<MyOptionType, true> = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: 'white',
    border: '1px solid #E5E5E5',
    outline: 'none',
    borderColor: 'transparent',
    boxShadow: 'none',
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isFocused ? '#081D57' : '#7A7A7C',
    backgroundColor: state.isFocused ? '#F3F3F3' : '#FFFFFF',
    outline: 'none',
    width: 'auto',
    cursor: 'pointer',
    fontSize: '14px',
  }),
  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    columnGap: '4px',
  }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
    border: 'none',
  }),
  menuPortal: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  multiValueRemove: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: '#e6e5e5',
    paddingLeft: '2px',
    paddingRight: '2px',
  }),
  multiValueLabel: (baseStyles, state) => ({
    ...baseStyles,
    color: 'black',
    fontSize: '12px',
    textOverflow: 'unset',
    overflow: 'visible',
    paddingLeft: '2px',
    padding: '2px',
  }),
};

export const reactSelectStylesDownloadFormat: StylesConfig<MyOptionType, false> = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: 'white',
    border: '1px solid #E5E5E5',
    outline: 'none',
    borderColor: 'transparent',
    boxShadow: 'none',
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  container: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isFocused ? '#081D57' : '#7A7A7C',
    backgroundColor: state.isFocused ? '#F3F3F3' : '#FFFFFF',
    outline: 'none',
    width: 'auto',
    cursor: 'pointer',
    fontSize: '14px',
  }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
    border: 'none',
  }),
  menuPortal: (baseStyles, state) => ({
    ...baseStyles,
    outline: 'none',
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: 'black',
    fontWeight: 500,
    outline: 'none',
  }),
};

export const TooltipStyles = {
  display: 'flex',
  width: '179px',
  padding: '16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '16px',
  background: '#FFF',
  boxShadow: '0px 12px 20px 0px rgba(0, 0, 0, 0.05)',
  color: 'var(--colors-primary-slate-600, #455468)',
  fontFamily: 'Lota Grotesque',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '-0.3px',
  wordBreak: 'break-all',
  zIndex: 10,
} satisfies CSSProperties;

export const TooltipStylesComments = {
  display: 'flex',
  width: '279px',
  padding: '16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '16px',
  background: '#FFFFFF',
  boxShadow: '0px 12px 20px 0px rgba(0, 0, 0, 0.05)',
  color: 'var(--colors-primary-slate-600, #455468)',
  fontFamily: 'Lota Grotesque',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '-0.3px',
  zIndex: '100 !important',
};

export const TooltipStylesTable = {
  display: 'flex',
  width: '280px',
  padding: '16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '16px',
  background: '#FFF',
  boxShadow: '0px 12px 20px 0px rgba(0, 0, 0, 0.05)',
  color: 'var(--colors-primary-slate-600, #455468)',
  fontFamily: 'Lota Grotesque',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '-0.3px',
};

export type IMPORTANT_COLORS_KEYS = 'Yes' | 'May be relevant' | 'No';
export type AGREED_COLORS_KEYS = 'Yes' | 'Potentially not agreed' | 'No';
export const IMPORTANT_COLOR_MAP = {
  Yes: 'green',
  'May be relevant': 'orange',
  No: 'red',
};
export const AGREED_COLOR_MAP = {
  Yes: 'green',
  'Potentially not agreed': 'orange',
  No: 'red',
};
