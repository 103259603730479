import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { FetchOrgsResponse } from 'types';

const useFetchOrganisations = (enabled: boolean = false) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<FetchOrgsResponse>(
    'fetchOrgs',
    () => {
      const url = new URL(`${APIBaseChronos}/api/org`);

      return fetch(url.toString(), fetchConfigGET)
        .then((res) => res.json())
        .catch((err) => {
          console.error(err);
        });
    },
    {
      cacheTime: 0,
      enabled: enabled,
    },
  );
};

export default useFetchOrganisations;
