import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

const useGetThreadStatus = (caseId: string, threadId: string) => {
  const { fetchConfigGET } = useGetFetchConfig();

  const getThreadStatus = async () => {
    const response = await fetch(`${APIBaseChronos}/api/case/${caseId}/thread/${threadId}`, fetchConfigGET);
    if (!response.ok) {
      throw new Error('Fetching messages failed');
    }
    return response.json();
  };

  const { data, refetch, error } = useQuery(['threadStatus', threadId], getThreadStatus, {
    cacheTime: 0,
    enabled: false,
  });

  return {
    data,
    refetch,
    error,
  };
};

export default useGetThreadStatus;
