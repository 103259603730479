import { useEffect, useState } from 'react';

import { faAdd, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/atoms/Button';
import { BreadcrumbHeading } from 'components/molecules/Breadcumb';
import EmptyState from 'components/molecules/EmptyState';
import ConfirmationModal from 'components/molecules/Modals/Confirmation';
import SearchBox from 'components/molecules/SearchBox';
import Pagination from 'components/organisms/Pagination';
import { PIPELINE_STATUS } from 'constants/pipelineStatus';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ChronosItem } from 'types';

import CaseCard from './components/CaseCard';
import { useCreateCase } from './hooks/useCreateCase';
import { useDeleteCase } from './hooks/useDeleteCase';
import { useGetMatter } from './hooks/useGetCaseMatter';
import { useCasesQuery } from './hooks/useGetCases';

const CasesExplorer = () => {
  // State

  const [searchText, setSearchText] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [caseIdToDelete, setCaseIdToDelete] = useState('');

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const pageQuery = parseInt(searchParams.get('page') || '0') || 0;
  const matterId = searchParams.get('matterId');

  const {
    isLoading: isLoadingCases,
    data: casesData,
    refetch: refetchCases,
  } = useCasesQuery({ matterId, searchText, pageQuery });

  const { isLoading: isLoadingMatter, data: matterData } = useGetMatter({ matterId });

  const { mutate: createCase, isLoading: isCreating } = useCreateCase();

  const onDeleteSuccess = (data: Response) => {
    if (data.ok) {
      toast.success('Case deleted successfully');
    } else {
      toast.error('Error deleting case. Try again later');
    }
    setConfirmDeleteModalOpen(false);
    setCaseIdToDelete('');
    refetchCases();
  };

  const onDeleteError = () => {
    toast.error('Error deleting case. Try again later');
    setConfirmDeleteModalOpen(false);
    setCaseIdToDelete('');
  };

  const { mutate: deleteCase, isLoading: isDeleting } = useDeleteCase({
    onError: onDeleteError,
    onSuccess: onDeleteSuccess,
  });

  useEffect(() => {
    if (matterData?.type === 'unauthorized') {
      toast.error('Unauthorized. You are being redirected to the matters explorer.');
      navigate(`/app/chronos/matters`);
    }
  }, [matterData, navigate]);

  useEffect(() => {
    setTotalPages(casesData?.total_pages || 1);
  }, [casesData]);

  // Handlers
  const handleViewCase = (id: string, pipeline_status: string) => {
    // Don't navigate to summary page if it's not ready yet
    if ([PIPELINE_STATUS.draft.value].includes(pipeline_status)) {
      navigate(`/app/chronos/case-creator/${id}`);
    } else {
      navigate(`/app/chronos/case-editor/summary?caseId=${id}`);
    }
  };

  const handleGoToPreviousPage = () => {
    navigate(
      `/app/chronos/explore?matterId=${matterId}&page=${pageQuery - 1}${searchText ? `&searchText=${searchText}` : ''}`,
    );
  };

  const handleGoToNextPage = () => {
    navigate(
      `/app/chronos/explore?matterId=${matterId}&page=${pageQuery + 1}${searchText ? `&searchText=${searchText}` : ''}`,
    );
  };

  const goToPage = (value: number) => {
    navigate(`/app/chronos/explore?matterId=${matterId}&page=${value}${searchText ? `&searchText=${searchText}` : ''}`);
  };

  const onDeleteConfirm = () => {
    setConfirmDeleteModalOpen(false);
    deleteCase(caseIdToDelete);
  };

  return (
    <div className="flex h-screen w-full flex-col">
      <div className="min-h-0 w-full overflow-y-auto px-6 py-4">
        <div className="flex w-full flex-col gap-2 text-gray-800">
          <div className="mt-1 w-full text-2xl font-semibold not-italic text-gray-900">
            <BreadcrumbHeading
              pages={[
                {
                  label: <FontAwesomeIcon icon={faHome} className="h-4 w-4 text-gray-700" />,
                  href: '/app/chronos/matters',
                },
                {
                  label: <div>{isLoadingMatter ? '...' : matterData?.matterDetails?.matter_name}</div>,
                  href: `/app/chronos/explore?matterId=${matterId}`,
                },
              ]}
            />
          </div>

          <div className="relative mb-2 flex h-full min-h-[calc(100vh-6rem)] w-full rounded-lg border-2 border-gray-200 border-opacity-40 bg-white">
            <div className="w-full py-4">
              <div className="mb-4 flex w-full flex-col">
                <h2 className="mb-4 border-2 border-x-0 border-t-0 px-4 pb-1 text-lg font-semibold">Cases</h2>

                <div className="flex items-center justify-between gap-4 px-4">
                  <div className="flex flex-row items-center gap-6">
                    <SearchBox value={searchText} onChange={setSearchText} placeholder="Search cases..." />

                    <Button
                      type="brand"
                      rounded="base"
                      icon={<FontAwesomeIcon icon={faAdd} className="mr-2 text-white" />}
                      text="New Case"
                      onClick={() => createCase(matterId)}
                      disabled={isCreating}
                    />
                  </div>

                  {!isLoadingCases && casesData?.items?.length ? (
                    <Pagination
                      canGetNextPage={pageQuery < totalPages - 1}
                      canGetPrevPage={pageQuery > 0}
                      prevPage={handleGoToPreviousPage}
                      nextPage={handleGoToNextPage}
                      currentPage={pageQuery}
                      noOfPages={totalPages}
                      goToPage={goToPage}
                    />
                  ) : null}
                </div>
              </div>

              {isLoadingCases ? (
                <div className="flex w-full flex-row flex-wrap items-start gap-6 px-4">
                  {Array(6)
                    .fill(null)
                    .map((_, index) => (
                      <div
                        key={`skeleton-${index}`}
                        className="h-56 w-[calc(33.33%-1rem)] animate-pulse rounded-md bg-gray-100"
                      />
                    ))}
                </div>
              ) : (
                <div className="flex w-full flex-row flex-wrap items-start gap-6 px-4">
                  {casesData && casesData.items?.length > 0 ? (
                    casesData?.items?.map((item: ChronosItem) => {
                      return (
                        <div key={item.case_id} className="w-[calc(33.33%-1rem)]">
                          <CaseCard
                            item={item}
                            onClick={handleViewCase}
                            isDeleting={caseIdToDelete === item.case_id && isDeleting}
                            onDelete={() => {
                              setCaseIdToDelete(item.case_id);
                              setConfirmDeleteModalOpen(true);
                            }}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <EmptyState title="No existing cases found" subtitle="Create your first case!" />
                  )}
                </div>
              )}
            </div>

            <ConfirmationModal
              title="Delete Case"
              description="Deleting cases is irreversible. Are you sure you want to proceed?"
              isLoading={isDeleting}
              isOpen={confirmDeleteModalOpen}
              handleClose={() => setConfirmDeleteModalOpen(false)}
              onConfirm={onDeleteConfirm}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasesExplorer;
