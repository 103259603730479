import React, { useEffect, useRef, useState } from 'react';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthInfo } from '@propelauth/react';
import { reactSelectStylesCaseCreator } from 'constants/styles';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { StageSpinner } from 'react-spinners-kit';
import { MyOptionType } from 'types'; // Assuming type definitions are correctly placed

import CreateMatterButton from './components/CreateMatterButton';
import TextAreaWithLabel from './components/TextAreaWithLabel';
import UserList from './components/UserList';
import useGetMatter from './hooks/useGetMatter';
import useOrgUsers from './hooks/useOrgUsers';
import useUpdateMatter from './hooks/useUpdateMatter';
import { UserObject, UserObjectResponse } from './types/user';

const MatterCreator: React.FC = () => {
  const navigate = useNavigate();
  const { matterId } = useParams<{ matterId: string }>(); // Get matterId from URL
  const [matterName, setMatterName] = useState<string>('');
  const [matterDescription, setMatterDescription] = useState<string>('');
  const [matterCode, setMatterCode] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<UserObject[]>([]);
  const textAreaNameRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaDescriptionRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaMatterCodeRef = useRef<HTMLTextAreaElement | null>(null);
  const authInfo = useAuthInfo();

  const { data: orgUsers, refetch: refetchOrgUsers } = useOrgUsers();
  const { mutate: updateMatter, isLoading: isLoadingUpdate, data: updateResponse } = useUpdateMatter();

  const { isLoadingMatter, responseMatterData, refetchMatterData } = useGetMatter(matterId || '');

  useEffect(() => {
    if (matterId) {
      refetchMatterData();
    }
  }, [matterId, refetchMatterData]);

  // Pre-fill form when data is fetched
  useEffect(() => {
    if (responseMatterData) {
      setMatterName(responseMatterData.matterDetails.matter_name);
      setMatterDescription(responseMatterData.matterDetails.matter_description);
      setMatterCode(responseMatterData.matterDetails.matter_code);
      setSelectedUsers(
        responseMatterData.permissions
          .filter((x: any) => x.enabled)
          .map((x: any) => {
            return {
              user_id: x.user_id || '',
              user_email: x.user_email || '',
            };
          }),
      );
    }
  }, [responseMatterData]);

  useEffect(() => {
    if (updateResponse?.matterUpdate) {
      navigate(`/app/chronos/explore?matterId=${updateResponse?.matterUpdate?.matter_id}`);
    }
  }, [navigate, updateResponse]);

  useEffect(() => {
    refetchOrgUsers();
  }, [refetchOrgUsers]);

  const handleChangeMatterName = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMatterName(e.target.value);
  };

  const handleChangeMatterDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMatterDescription(e.target.value);
  };

  const handleChangeMatterCode = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMatterCode(e.target.value);
  };

  const handleUpdateMatter = () => {
    updateMatter({
      matterId: matterId || '',
      matterName,
      matterDescription,
      matterCode,
      selectedUsers,
    });
  };

  const handleClickBack = () => {
    navigate(`/app/chronos/explorer/matters`);
  };

  const handleRemoveUser = (userId: string) => {
    const indexToRemove = selectedUsers.findIndex((x) => x.user_id === userId);
    const arrayCopy = [...selectedUsers];
    arrayCopy.splice(indexToRemove, 1);
    setSelectedUsers(arrayCopy);
  };

  const handleChangeSelectedUsers = (userToAdd: MyOptionType) => {
    const newSelectedUsers = [
      ...selectedUsers,
      {
        user_id: userToAdd?.value,
        user_email: userToAdd?.label,
      },
    ] as UserObject[];
    setSelectedUsers(newSelectedUsers);
  };

  const canCreateMatter = matterName && matterDescription && selectedUsers;

  const userId = authInfo?.user?.userId;
  const selectedIds = selectedUsers.map((x) => x.user_id);
  const orgUsersOptions = orgUsers
    ?.filter((x: UserObjectResponse) => !selectedIds.includes(x.userId) && x.userId !== userId)
    .map((x: UserObjectResponse) => {
      return {
        value: x.userId,
        label: x.email,
      };
    }) as MyOptionType[];

  return (
    <div className="relative flex w-full flex-row overflow-auto text-white">
      {isLoadingMatter ? (
        <div className="flex h-full w-full items-center justify-center">
          <StageSpinner className="m-auto" size={25} color={'#081D57'} />
        </div>
      ) : (
        <>
          <div className="flex w-full flex-col items-start justify-start pl-8">
            <div className="mt-4 flex flex-row items-center justify-start">
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="mr-3 h-6 w-6 cursor-pointer text-black"
                onClick={handleClickBack}
              />
              <div className="w-full text-xl font-bold not-italic text-black">Update Matter</div>
            </div>
            <div className="" style={{ width: '690px' }}>
              <TextAreaWithLabel
                ref={textAreaNameRef}
                value={matterName}
                onChange={handleChangeMatterName}
                label="Name of Matter"
              />
              <TextAreaWithLabel
                ref={textAreaDescriptionRef}
                value={matterDescription}
                onChange={handleChangeMatterDescription}
                label="Description"
              />
              <TextAreaWithLabel
                ref={textAreaMatterCodeRef}
                value={matterCode}
                onChange={handleChangeMatterCode}
                label="Matter Code (optional)"
              />

              <div className="mt-5 w-full text-lg font-bold not-italic text-black">Members ({selectedIds.length})</div>

              <Select
                className={`mr-16 w-full rounded-md bg-white outline-none`}
                styles={reactSelectStylesCaseCreator}
                onChange={handleChangeSelectedUsers}
                options={orgUsersOptions}
                value={[]}
                placeholder="Select team members to include"
              />
              <UserList users={selectedUsers} onRemove={handleRemoveUser} />
            </div>
            <div className="mt-8">
              <CreateMatterButton
                isLoading={isLoadingUpdate || isLoadingMatter}
                isDisabled={!canCreateMatter}
                onClick={handleUpdateMatter}
                text={'Update Matter'}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MatterCreator;
