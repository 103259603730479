import { APIBaseChronos } from 'api/hosts'; // Ensure this path is correct
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation, useQueryClient } from 'react-query';

interface CaseMetadata {
  caseName: string;
}
interface UseUpdateCaseMetadata {
  caseId: string;
  data: CaseMetadata;
}

const useUpdateCaseMetadata = () => {
  const { getFetchConfig } = useGetFetchConfig();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ caseId, data }: UseUpdateCaseMetadata) => {
      return fetch(`${APIBaseChronos}/api/case/${caseId}/metadata`, getFetchConfig({ method: 'PATCH', data }))
        .then((res) => res.json())
        .catch((err) => {
          console.error(err);
        });
    },
    onSuccess: ({ caseId }) => {
      queryClient.invalidateQueries({ queryKey: ['cases', caseId] });
    },
  });
};

export default useUpdateCaseMetadata;
