import { faExclamationTriangle, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FactDropdown from 'components/molecules/FactDropdown';
import { useSearchParams } from 'react-router-dom';
import { ChronosFact } from 'types';

import IncludeCheckbox from '../../components/IncludeCheckbox';

interface FactesEditorTableProps {
  facts: ChronosFact[];
  keyFactIdSet: Set<string>;
  userId: string;
  caseId: string;
  setIsDateDirty: (v: boolean) => void;
  setTotalNumberOfIncludedFacts: (v: number | ((prev: number) => number)) => void;
  updateFactData: (eventId: string, data: Partial<ChronosFact>) => void;
}

const FactsEditorTable = ({
  facts,
  keyFactIdSet,
  userId,
  caseId,
  setIsDateDirty,
  setTotalNumberOfIncludedFacts,
  updateFactData,
}: FactesEditorTableProps) => {
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get('eventId');

  return (
    <div className="flex h-full flex-col gap-2 pb-6 pr-2 pt-1">
      {facts.length === 0 && (
        <div className="flex h-full flex-col items-center justify-center gap-2 text-gray-500">
          <FontAwesomeIcon icon={faExclamationTriangle} className="h-8 w-8 text-yellow-500" />
          No facts found
        </div>
      )}
      {facts.map((fact) => (
        <div key={fact.event_id} className="relative flex gap-3">
          <IncludeCheckbox
            included={fact.included}
            eventId={fact.event_id}
            caseId={caseId || ''}
            setTotalNumberOfIncludedFacts={setTotalNumberOfIncludedFacts}
          />
          <div
            className={`w-full rounded-lg border ${
              // Check if fact.event_id is set as event_id in search params
              eventId === fact.event_id
                ? 'green-glow-card border-brandSecondary border-opacity-50'
                : keyFactIdSet && keyFactIdSet.has(fact.event_id)
                  ? 'gold-glow-card border-yellow-500 border-opacity-50'
                  : ''
            } ${fact.important === 'No' ? 'border-dashed border-opacity-80' : ''}`}
          >
            <FactDropdown
              fact={fact}
              userId={userId}
              compressedVersion={true}
              setIsDateDirty={setIsDateDirty}
              updateFactData={updateFactData}
            />
            {keyFactIdSet && keyFactIdSet.has(fact.event_id) ? (
              <FontAwesomeIcon icon={faStar} className="absolute right-5 top-2 h-3 w-3 text-yellow-500" />
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FactsEditorTable;
