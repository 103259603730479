import { useState, useEffect, ReactNode, useCallback } from 'react';

import {
  faClipboardList,
  faCircleCheck,
  faCircleNotch,
  faTriangleExclamation,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import useDeleteThread from '../hooks/useDeleteThread';
import { ThreadStatus } from '../types';

interface ThreadItemProps {
  thread_text: string;
  thread_id: string;
  goToThread: (thread_id: string) => void;
  thread_status: ThreadStatus;
  isActive: boolean;
}

const ACTIVE_STATUSES: ThreadStatus[] = ['plan-executing', 'plan-loading'];

const Icon = ({ status, icon }: { status: ThreadStatus; icon: ReactNode }) => {
  return ACTIVE_STATUSES.includes(status) ? (
    <FontAwesomeIcon icon={faCircleNotch} className="fa-spin mr-2 h-3 w-3" />
  ) : status === 'plan-loaded' ? (
    <FontAwesomeIcon icon={faClipboardList} className="mr-2 h-3 w-3 text-gray-600" />
  ) : status === 'failed' ? (
    <FontAwesomeIcon icon={faTriangleExclamation} className="mr-2 h-3 w-3 text-red-600" />
  ) : status === 'plan-complete' && icon ? (
    <div className="flex animate-popInHalf items-center">{icon}</div>
  ) : null;
};

const ThreadsBarItem: React.FC<ThreadItemProps> = ({ thread_text, thread_id, goToThread, thread_status, isActive }) => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<ThreadStatus>(thread_status);
  const [icon, setIcon] = useState<ReactNode>();
  const caseId = searchParams.get('caseId');
  const { fetchConfigGET } = useGetFetchConfig();

  const { mutate: deleteThread, isLoading } = useDeleteThread(caseId || '');

  const onDeleteThreadClick = () => deleteThread(thread_id);

  const fetchThread = useCallback(
    async (threadId: string) => {
      const response = await fetch(`${APIBaseChronos}/api/case/assistant/thread/${caseId}/${threadId}`, fetchConfigGET);
      if (!response.ok) {
        throw new Error('Fetching thread failed');
      }
      return response.json();
    },
    [caseId, fetchConfigGET],
  );

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const pollThread = async (threadId: string) => {
      const response = await fetchThread(threadId);
      const thread = response.thread[0];
      if (!thread) return;
      const currentStatus: ThreadStatus = thread.status;

      if (currentStatus === 'plan-loaded') {
        setIcon(<FontAwesomeIcon icon={faClipboardList} className="mr-2 h-3 w-3 text-gray-600" />);
        setStatus(currentStatus);
      } else if (currentStatus === 'plan-complete') {
        setStatus(currentStatus);
        setIcon(<FontAwesomeIcon icon={faCircleCheck} className="mr-2 h-3 w-3 text-green-600" />);
        setTimeout(() => {
          setIcon(undefined);
        }, 2000);
      }
    };

    if (status === 'plan-executing' || status === 'plan-loading') {
      intervalId = setInterval(() => {
        pollThread(thread_id);
      }, 4000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [status, thread_id, fetchThread]);

  const canDelete = !ACTIVE_STATUSES.includes(status) && !isActive;

  return (
    <div
      className={twMerge(
        `group flex cursor-pointer items-center justify-between rounded border transition-colors duration-150 hover:bg-gray-200`,
        status === 'plan-complete' && icon ? 'animate-border-pulse-green' : 'border-gray-50 border-opacity-50',
        isActive && 'bg-gray-300',
      )}
    >
      <div
        className={`w-5/6 cursor-pointer overflow-hidden text-ellipsis px-2 py-2 text-xs`}
        onClick={() => thread_id && goToThread(thread_id)}
      >
        <div className="overflow-hidden text-ellipsis whitespace-nowrap">{thread_text}</div>
      </div>

      <Icon status={status} icon={icon} />
      {canDelete && (
        <FontAwesomeIcon
          icon={isLoading ? faCircleNotch : faTrash}
          onClick={onDeleteThreadClick}
          className={twMerge(
            'mr-2 h-3 w-3 opacity-0 transition-opacity duration-500 hover:text-gray-500 group-hover:opacity-100',
            isLoading && 'fa-spin opacity-100',
          )}
        />
      )}
    </div>
  );
};

export default ThreadsBarItem;
