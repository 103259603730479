import { useState } from 'react';

import { APIBaseChronos } from 'api/hosts';
import { ReactComponent as ReactInfoYellow } from 'assets/icons/info-yellow.svg';
import { ReactComponent as ReactInfo } from 'assets/icons/info.svg';
import TooltipIcon from 'components/atoms/TooltipIcon';
import CalendarEditPopup from 'components/molecules/CalendarPopup';
import CommentPopup from 'components/molecules/Comments';
import DropdownInput from 'components/molecules/FactDropdown/DropdownInput';
import TextInput from 'components/molecules/TextInput';
import { IMPORTANCY_OPTIONS } from 'constants/chronos_table_options';
import { TooltipStyles } from 'constants/styles';
import { useUserContext } from 'Contexts/User';
import { renderHighlightedTextWithLinks } from 'helpers/textRendering';
import { Tooltip } from 'react-tooltip';
import { ChronosFact } from 'types';

const DocViewerFullFact = ({ fact, handleFactUpdated }: { fact: ChronosFact; handleFactUpdated: () => void }) => {
  const [factText, setFactText] = useState(fact.action_described);
  const [dateText, setDateText] = useState(fact.date_of_subject_text);
  const [significanceText, setSignificanceText] = useState(fact.relevance_reason);
  const [relevanceText, setRelevanceText] = useState(fact.important);
  const { user } = useUserContext();

  return (
    <div className="text-xs">
      <div className="flex w-full items-center justify-between font-normal">
        <div className={`w-full p-[6px] px-2 pl-2`}>
          <div className="flex w-full justify-between">
            <div className={`flex items-center gap-2 text-xs font-semibold`}>
              <CalendarEditPopup
                dateText={dateText}
                entryFieldKey="date_of_subject_text"
                updateDate={setDateText}
                compressedVersion={true}
                endpointToUpdate={`${APIBaseChronos}/api/fact/${fact.event_id}`}
                location="Fact"
                callback={handleFactUpdated}
              />

              {dateText ? dateText : '(Date Unknown)'}

              {!!fact.date_of_subject_is_ambiguous && (
                <>
                  <ReactInfoYellow
                    data-tooltip-id={`fact-date-text-tooltip-${fact.event_id}-ambiguous`}
                    data-tooltip-content={
                      fact.date_of_subject_is_ambiguous_text || 'Date format is ambiguous, please check'
                    }
                    className="cursor-pointer text-sm text-gray-700"
                    style={{ color: '#E3B336' }}
                  />
                  <Tooltip
                    opacity={1}
                    id={`fact-date-text-tooltip-${fact.event_id}-ambiguous`}
                    style={TooltipStyles}
                    place={'top-start'}
                  />
                </>
              )}
              {!!fact.is_future_date && !fact.date_of_subject_is_ambiguous && (
                <>
                  <ReactInfo
                    data-tooltip-id={`fact-date-text-tooltip-${fact.event_id}`}
                    data-tooltip-content="Hypothetical future date"
                    className="cursor-pointer text-sm text-gray-700"
                    style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
                  />
                  <Tooltip opacity={1} id={`fact-date-text-tooltip-${fact.event_id}`} style={TooltipStyles} />
                </>
              )}
            </div>
          </div>

          <div className="flex justify-between gap-1">
            <div className={`w-full pl-2 pt-1 text-xs text-gray-900`}>
              <TextInput
                editable={true}
                entryId={fact.event_id}
                entryFieldKey={'action_described'}
                entryFieldValue={factText}
                endpointToUpdate={`${APIBaseChronos}/api/fact/${fact.event_id}`}
                updateState={setFactText}
                location="Fact"
                callback={handleFactUpdated}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-2 border border-x-0 border-t-0 border-dashed border-blue-200"></div>

      <div className="flex flex-col gap-2 py-4 pl-2 pr-4">
        <div className="w-full">
          <div className="mb-1 flex items-center gap-1 px-2 font-semibold text-gray-800">
            Significance
            <TooltipIcon
              tooltipId={`significance-tooltip-${fact.event_id}`}
              tooltipContent="A short analysis of the significance of the fact as it pertains to the key case issues and the context of the document it is extracted from."
              className="mr-2 h-4 w-4 cursor-pointer"
            />
          </div>
          <div className="px-2 text-gray-800">
            <TextInput
              editable={true}
              entryId={fact.event_id}
              entryFieldKey={'relevance_reason'}
              entryFieldValue={significanceText}
              endpointToUpdate={`${APIBaseChronos}/api/fact/${fact.event_id}`}
              updateState={setSignificanceText}
              location="Fact"
              callback={handleFactUpdated}
            />
          </div>
        </div>

        <div className="mt-2 border border-x-0 border-t-0 border-dashed border-blue-200"></div>

        <div className="flex justify-between px-2">
          <div className="w-1/2">
            <div className="mb-1 flex items-center gap-1 font-semibold text-gray-800">
              Relevant
              <TooltipIcon
                tooltipId={`relevance-tooltip-${fact.event_id}`}
                tooltipContent="Is this fact relevant to the initial case synopsis."
                className="mr-2 h-4 w-4 cursor-pointer"
                place="left"
              />
            </div>
            <div
              className={`${
                relevanceText === 'Yes' ? 'text-green-700' : relevanceText === 'No' ? 'text-red-500' : 'text-orange-400'
              }`}
            >
              <DropdownInput
                entryFieldKey={'important'}
                entryFieldValue={relevanceText}
                endpointToUpdate={`${APIBaseChronos}/api/fact/${fact.event_id}`}
                options={IMPORTANCY_OPTIONS}
                updateRelevance={setRelevanceText}
                callback={handleFactUpdated}
              />
            </div>
          </div>

          <div className="w-1/2">
            <div className="mb-1 flex items-center gap-1 font-semibold text-gray-800">
              Classification
              <TooltipIcon
                tooltipId={`classification-tooltip-${fact.event_id}`}
                tooltipContent="Fact classification. One of Primary, Secondary, Future or Litigation."
                className="mr-2 h-4 w-4 cursor-pointer"
                place="left"
              />
            </div>
            <div>
              {fact.classification ? fact.classification.charAt(0).toUpperCase() + fact.classification.slice(1) : ''}
            </div>
          </div>
        </div>
      </div>

      <div className="border-2 border-x-0 border-t-0 border-blue-200"></div>

      <div className="flex justify-between gap-10 rounded-b-lg bg-slate-100 px-4">
        <div>
          <div className="pb-1 pt-2 font-semibold">Comments</div>
          <div>
            {fact.first_comment ? (
              renderHighlightedTextWithLinks(fact.first_comment)
            ) : (
              <span className="font-light text-gray-500">No comments yet</span>
            )}
          </div>
        </div>
        <div className="flex items-center gap-2 py-3">
          <CommentPopup
            thread_id={fact.event_id}
            comments_count={fact.comments_count || 0}
            currentUserId={user?.userId || ''}
          />
        </div>
      </div>
    </div>
  );
};

export default DocViewerFullFact;
