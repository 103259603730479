import React, { useState } from 'react';

import { faEye, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/atoms/Button';
import moment from 'moment';

import { MatterItem } from '../../../../types';

export interface MatterItemComponentProps {
  item: MatterItem;
  isCreator: boolean;
  isSuperAdmin: boolean;
  onEdit: (id: string) => void;
  onView: (id: string) => void;
}

const MatterItemComponent: React.FC<MatterItemComponentProps> = ({ item, isCreator, onEdit, onView, isSuperAdmin }) => {
  const [isExpanded, setIsExpanded] = useState(false); // State to manage text expansion

  return (
    <div className="flex w-full flex-col items-start justify-start rounded-md border border-gray-200 px-6 py-1 pb-2 text-gray-800 shadow-sm">
      <div className="flex w-full flex-row items-center justify-between">
        {item.latest_matter_name && <div className="text-sm font-semibold not-italic">{item.latest_matter_name}</div>}
        <div className="right-0 mt-2 flex items-center justify-between overflow-hidden text-xs font-semibold not-italic leading-5 text-green-700">
          Created: {`${moment(item.created_date).format('MMMM DD, YYYY')}`}
        </div>
      </div>
      {item.latest_matter_code && <div className="text-xs not-italic text-gray-500">{item.latest_matter_code}</div>}
      {item.latest_matter_description && (
        <button
          className={`overflow-hidden text-left text-xs font-normal not-italic leading-4 ${
            isExpanded ? '' : 'line-clamp-3'
          }`}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          {item.latest_matter_description}
        </button>
      )}

      <div className="flex w-full items-center justify-between">
        <div className="mt-3 flex flex-row gap-4">
          <Button
            rounded="base"
            size="xs"
            text="View"
            type="primary"
            onClick={() => onView(item.matter_id)}
            icon={<FontAwesomeIcon icon={faEye} className="mr-2 text-white" />}
          />

          {(isCreator || isSuperAdmin) && (
            <Button
              rounded="base"
              size="xs"
              text="Edit"
              type="secondary"
              onClick={() => onEdit(item.matter_id)}
              icon={<FontAwesomeIcon icon={faPencil} className="mr-2 text-gray-800" />}
            />
          )}
        </div>
        {item.ultimate_edit_date && (
          <div className="mt-3 flex items-start rounded bg-yellow-200 bg-opacity-20 px-2 py-1 text-xs font-normal italic leading-4">
            {`Last updated ${item.editor_email ? `by ${item.editor_email}` : ''} on ${moment(
              item.ultimate_edit_date,
            ).format('DD/MM/YYYY')}`}
          </div>
        )}
      </div>
    </div>
  );
};

export default MatterItemComponent;
