import { HighlightArea } from '@react-pdf-viewer/highlight';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { APIBaseChronos } from '../hosts';
import { QueryEntity } from '../types';
import useGetFetchConfig from '../useGetFetchConfig';

interface UseAddFact {
  actionDescribed: string;
  dateOfSubjectMatterText: string;
  importancy: string;
  classification: string;
  significance: string;
  highlightCoords: HighlightArea[];
}

const formatCoordinatesForApi = (coords: UseAddFact['highlightCoords']) => {
  // this is so that the format of these coordinates is the same as those created by the ML pipeline
  return coords.map((coord) => ({
    height_c: coord.height / 100,
    left_c: coord.left / 100,
    top_c: coord.top / 100,
    width_c: coord.left / 100,
    n_page: coord.pageIndex + 1,
  }));
};

const useAddFact = ({ docId, onSuccess }: { docId: string | null; onSuccess: VoidFunction }) => {
  const { getFetchConfig } = useGetFetchConfig();
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess,
    mutationFn: async ({
      actionDescribed,
      dateOfSubjectMatterText,
      classification,
      importancy,
      significance,
      highlightCoords,
    }: UseAddFact) => {
      const fetchConfig = getFetchConfig({
        method: 'POST',
        data: {
          action_described: actionDescribed,
          date_of_subject_text: dateOfSubjectMatterText,
          important: importancy,
          classification: classification,
          relevance_reason: significance,
          event_coordinates: formatCoordinatesForApi(highlightCoords),
        },
      });

      return fetch(`${APIBaseChronos}/api/docs/${docId}/fact`, fetchConfig)
        .then((res) => {
          if (!res.ok) {
            throw new Error('Failed to create fact');
          }
          toast.success('A new fact was created succesfully');

          queryClient.invalidateQueries({ queryKey: [QueryEntity.CaseEvent, { docId }] });
          queryClient.invalidateQueries({ queryKey: [QueryEntity.CaseEventCoordinates, { docId }] });
        })
        .catch((err) => {
          console.error('Fetch Error: ', err);
          toast.error('There was an error while adding new fact. Please try again later.');
        });
    },
  });
};

export default useAddFact;
