import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

import { ThreadObject } from '../types';

const useGetThreads = (caseId: string) => {
  const { fetchConfigGET } = useGetFetchConfig();
  const getThreads = async () => {
    if (!caseId) {
      return [];
    }

    const response = await fetch(`${APIBaseChronos}/api/case/${caseId}/threads`, fetchConfigGET);
    if (!response.ok) {
      throw new Error('Fetching threads failed');
    }
    return response.json();
  };

  const {
    isFetching,
    data = [],
    error,
  } = useQuery<ThreadObject[]>(['threads', caseId], getThreads, {
    refetchOnWindowFocus: false,
  });

  return {
    isFetching,
    threads: data,
    error,
  };
};

export default useGetThreads;
