import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

const useGetCaseOverview = (caseId: string | null = '') => {
  const { fetchConfigGET } = useGetFetchConfig();

  const getCaseOverview = async () => {
    const response = await fetch(`${APIBaseChronos}/api/case/${caseId}/overview`, fetchConfigGET);
    if (!response.ok) {
      throw new Error('Fetching case overview failed');
    }
    const data = await response.json();
    return data;
  };

  return useQuery(['caseOverview', caseId], getCaseOverview, {
    refetchOnWindowFocus: false,
  });
};

export default useGetCaseOverview;
