import React, { useEffect, useState } from 'react';

import 'react-toggle/style.css';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';

import useToggleIncluded from '../../FactsEditor/hooks/useToggleIncluded';

interface IncludeCheckboxProps {
  included: boolean;
  eventId: string;
  caseId: string;
  setTotalNumberOfIncludedFacts: (v: number | ((prev: number) => number)) => void;
  callback?: () => void;
  editorIdEmail?: string;
}
const IncludeCheckbox = ({
  included: currentIncluded,
  eventId,
  caseId,
  callback,
  setTotalNumberOfIncludedFacts,
}: IncludeCheckboxProps) => {
  const [checked, setChecked] = useState<boolean>(currentIncluded);

  const { mutate: mutateUpdateIncluded } = useToggleIncluded();

  useEffect(() => {
    setChecked(currentIncluded);
  }, [currentIncluded]);

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const toggleValue = event.target.checked;
    setChecked(toggleValue);
    setTotalNumberOfIncludedFacts((prev: number) => prev + (toggleValue ? 1 : -1));
    mutateUpdateIncluded(
      { caseId, toggleValue, eventId },
      {
        onSuccess: () => {
          if (callback) callback();
        },
        onError: (error: any) => {
          Swal.fire({
            title: 'Error',
            text: 'Failed to update the event',
            icon: 'error',
            confirmButtonText: 'Ok',
          });
          setChecked(!toggleValue);
        },
      },
    );
  };

  return (
    <div className="flex w-auto flex-row items-center justify-center">
      <label className="flex cursor-pointer items-center">
        <input
          onChange={handleToggle}
          id={`default-checkbox-${eventId}`}
          type="checkbox"
          checked={checked}
          className="peer hidden"
        />
        <div className="flex h-4 w-4 items-center justify-center rounded-full border border-gray-300 bg-buttonSecondary peer-checked:bg-brandSecondary">
          <FontAwesomeIcon icon={faCheck} className="h-2 w-2 text-white peer-checked:inline-block" />
        </div>
      </label>
    </div>
  );
};

export default IncludeCheckbox;
