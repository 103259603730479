import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery, UseQueryResult } from 'react-query';

function useFetchDocIds(caseId: string | null): UseQueryResult<any, unknown> {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<any, unknown>(
    ['userDocs'],
    () =>
      fetch(`${APIBaseChronos}/api/case/${caseId}/doc-ids`, fetchConfigGET).then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      }),
    {
      enabled: !!caseId, // Ensure caseId is not null before fetching
    },
  );
}

export default useFetchDocIds;
