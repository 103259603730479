import React from 'react';

import { FetchOrgUsageDataRes } from 'api/types/queries';

import StatisticItem from '../StatisticItem';

interface StatisticsGridProps {
  isFetching: boolean;
  data?: FetchOrgUsageDataRes;
}

const StatisticsGrid: React.FC<StatisticsGridProps> = ({ isFetching, data }) => (
  <div className="flex w-full max-w-7xl flex-row gap-4">
    <StatisticItem title="Pages processed" value={data ? data.pageCountTotal : null} isFetching={isFetching} />
    <StatisticItem title="Runs submitted" value={data ? data.runCountTotal : null} isFetching={isFetching} />
    <StatisticItem
      title="Chronologies created"
      value={data ? data.chronologyCountTotal : null}
      isFetching={isFetching}
    />
    <StatisticItem title="Events extracted" value={data ? data.eventCountTotal : null} isFetching={isFetching} />
    <StatisticItem title="Kim threads" value={data ? data.kimThreadCountTotal : null} isFetching={isFetching} />
    <StatisticItem title="Tokens used" value={data ? data.tokensUsed : null} isFetching={isFetching} />
  </div>
);

export default StatisticsGrid;
