import { memo, useCallback, useEffect, useState } from 'react';

import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import Button from 'components/atoms/Button';
import EditableField from 'components/molecules/EditableField';
import moment from 'moment';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import { ChronosChronology } from 'types';

interface ChronologyListItemProps {
  chronology: ChronosChronology;
  setChronologiesListDeleteMap: (
    newState: Record<string, boolean> | ((prev: Record<string, boolean>) => Record<string, boolean>),
  ) => void;
  chronologiesListDeleteMap: Record<string, boolean>;
}

const ChronologyListItem = memo(
  ({ chronology, setChronologiesListDeleteMap, chronologiesListDeleteMap }: ChronologyListItemProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isDeleting, setIsDeleting] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [currentName, setCurrentName] = useState(chronology.latest_title || '');
    const { getFetchConfig } = useGetFetchConfig();

    const fetchConfig = getFetchConfig({
      method: 'PATCH',
      data: {
        title: currentName,
      },
    });

    const { isFetching: isLoadingUpdateChronologyName, refetch: updateChronologyName } = useQuery(
      ['updateFieldValue', chronology.chronology_id],
      () => {
        return fetch(`${APIBaseChronos}/api/chronology/${chronology.chronology_id}`, fetchConfig)
          .then((res) => {
            setEditMode(false);
            return res.json();
          })
          .catch((err) => {
            console.error('Fetch Error: ', err);
            Swal.fire({
              title: 'Error on update',
              text: 'There was an error on updating the chronology name. Please try again later.',
              showConfirmButton: false,
              timer: 3000,
            });
          });
      },
      {
        cacheTime: 0,
        enabled: false,
      },
    );

    const onChronologyClick = useCallback(() => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('page', '1');
      searchParams.set('chronologyId', chronology.chronology_id);
      searchParams.set('title', currentName);
      navigate('/app/chronos/case-editor/data/chronology/view?' + searchParams.toString());

      // eslint-disable-next-line
    }, [chronology.chronology_id, currentName]);

    const onEditSave = useCallback(
      (updatedValue: string) => {
        setCurrentName(updatedValue);
      },
      [setCurrentName],
    );

    useEffect(() => {
      if (currentName !== chronology.latest_title) {
        updateChronologyName();
      }
    }, [currentName, chronology.latest_title, updateChronologyName]);

    const onDeleteClick = useCallback(() => {
      Swal.fire({
        title: '',
        text: 'Deleting chronologies is irreversible. Do you want to proceed?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Proceed',
        cancelButtonText: 'Cancel',
        timer: 10000,
        confirmButtonColor: 'red',
      }).then((result: any) => {
        if (result.isConfirmed) {
          setIsDeleting(true);
          const fetchConfig = getFetchConfig({
            method: 'DELETE',
          });
          fetch(`${APIBaseChronos}/api/chronology/${chronology.chronology_id}`, fetchConfig).then((res) => {
            if (res.ok) {
              Swal.fire({
                title: '',
                text: 'Chronology deleted successfully',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000,
              });
              setChronologiesListDeleteMap((prev: any) => ({ ...prev, [chronology.chronology_id]: true }));
            } else if (res.status === 403) {
              Swal.fire({
                title: 'Not authorized',
                text: 'You are not the creator of this chronology',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000,
              });
              setIsDeleting(false);
            } else {
              Swal.fire({
                title: '',
                text: 'Error deleting chronology. Try again later',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000,
              });
              setIsDeleting(false);
            }
          });
        }
      });
      // eslint-disable-next-line
    }, [chronology.chronology_id]);

    if (chronologiesListDeleteMap[chronology.chronology_id]) {
      return null;
    }

    return isDeleting ? (
      <div className="mb-3 flex h-full w-full flex-col items-center justify-center rounded-lg border bg-gray-50 p-8">
        <StageSpinner className="m-auto" size={28} color={'#4161FF'} />
      </div>
    ) : (
      <div
        className="mb-3 flex w-full flex-col items-start justify-start rounded-lg border px-6 py-4 text-sm shadow"
        key={chronology.chronology_id}
      >
        <EditableField
          value={chronology.latest_title}
          onFieldClick={onChronologyClick}
          isLoading={isLoadingUpdateChronologyName}
          onSave={onEditSave}
          textClassName="not-italic font-semibold"
        />
        <div className="right-0 mt-1 flex items-center justify-between overflow-hidden text-sm not-italic leading-5 text-green-700">
          Created: {`${moment(chronology.created_date || Date.now()).format('MMMM DD, YYYY')}`}
        </div>
        <div className="mt-2 flex w-full gap-4">
          <Button text="View" type="primary" size="xs" rounded="md" onClick={onChronologyClick} disabled={editMode} />
          <Button text="Delete chronology" type="delete" size="xs" rounded="md" onClick={onDeleteClick} />
          <div className="ml-auto flex items-start rounded bg-yellow-200 bg-opacity-20 px-2 py-1 text-xs italic text-gray-800">
            {`Last updated by ${chronology.editor_email} on ${moment(
              chronology.ultimate_edit_date || chronology.created_date,
            ).format('DD/MM/YYYY HH:mm')}`}
          </div>
        </div>
      </div>
    );
  },
);

export default ChronologyListItem;
