import { useEffect, useState } from 'react';

import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackCustomEvent } from 'analytics/Mixpanel';
import { ReactComponent as FileIcon } from 'assets/guide/homepage-guides/file-icon.svg';
import { ReactComponent as HammerAndWrenchIcon } from 'assets/guide/homepage-guides/hammer-and-wrench-icon.svg';
import { ReactComponent as MagnifyingGlassIcon } from 'assets/guide/homepage-guides/magnifying-glass-icon.svg';
import { ReactComponent as QuestionIcon } from 'assets/guide/homepage-guides/question-icon.svg';
import CustomModal from 'components/molecules/Modals/Custom';

import useGetCompletedGuides from '../../hooks/useGetCompletedGuides';
// import useUpdateCompletedGuides from '../../../screens/Chronos/MattersExplorer/components/ActivityPanel/hooks/useUpdateCompletedGuides';

interface Guide {
  title: string;
  description: string;
  icon: React.ReactNode;
  value: string;
  status: 'completed' | 'not-started';
}

const GUIDES_WITHOUT_STATUS = [
  {
    title: 'Viewing documents',
    description: 'Learn how to view documents in Wexler',
    icon: <FileIcon className="h-4 w-4" />,
    value: 'viewing-documents',
  },
  {
    title: 'Exploring facts',
    description: 'Learn how to view the facts Wexler has extracted',
    icon: <MagnifyingGlassIcon className="h-4 w-4" />,
    value: 'exploring-facts',
  },
  {
    title: 'Using Kim',
    description: 'Learn how to use our AI agent, Kim, to ask questions',
    icon: <QuestionIcon className="h-4 w-4" />,
    value: 'using-kim',
  },
  {
    title: 'Creating a case',
    description: 'Learn how to create your first case',
    icon: <HammerAndWrenchIcon className="h-4 w-4" />,
    value: 'creating-a-case',
  },
  {
    title: 'Creating a matter',
    description: 'Learn how to create your first matter',
    icon: <HammerAndWrenchIcon className="h-4 w-4" />,
    value: 'creating-a-matter',
  },
];

const MODAL_CONTENT: Record<string, { title: string; description: string }> = {
  'creating-a-matter': {
    title: 'Creating a matter',
    description: 'Watch the video below to learn how to create your first matter',
  },
  'creating-a-case': {
    title: 'Creating a case',
    description: 'Watch the video below to learn how to create your first case',
  },
  'using-kim': {
    title: 'Using Kim',
    description: 'Watch the video below to learn how to use our AI agent, Kim, to ask questions',
  },
  'exploring-facts': {
    title: 'Exploring facts',
    description: 'Watch the video below to learn how to view the facts Wexler has extracted',
  },
  'viewing-documents': {
    title: 'Viewing documents',
    description: 'Watch the video below to learn how to view documents in Wexler',
  },
};

const GuideModal = ({
  title,
  description,
  guideName,
  userId,
}: {
  title: string;
  description: string;
  guideName: string;
  userId: string;
}) => {
  // const { mutate: updateCompletedGuides } = useUpdateCompletedGuides();
  // const handleMarkAsCompleted = () => {
  //   updateCompletedGuides({ userId, guideName, guideState: 'completed' });
  // };

  return (
    <div className="p-6">
      <h2 className="mb-2 border border-x-0 border-t-0 pb-1 font-semibold">{title}</h2>
      <p className="text-sm text-gray-700">{description}</p>
      <div className="mt-4 h-64 w-full rounded-md bg-gray-100"></div>
    </div>
  );
};

const GuideItem = ({
  title,
  description,
  icon,
  onClick,
  status,
}: {
  title: string;
  description: string;
  icon: React.ReactNode;
  onClick: () => void;
  status: 'completed' | 'not-started';
}) => {
  return status === 'completed' ? (
    <div
      onClick={onClick}
      className="flex w-full cursor-pointer items-center justify-between rounded-md border border-gray-300 bg-gray-100 p-2 text-xs text-gray-400 shadow transition-all duration-300 hover:bg-gray-200"
    >
      <div className="flex flex-row items-center gap-2">
        <p className="text-sm font-semibold">{title}</p>
        <span>{icon}</span>
      </div>
      <div className="flex flex-row items-center gap-2 text-green-600">
        <span>Completed</span>
        <FontAwesomeIcon icon={faCircleCheck} />
      </div>
    </div>
  ) : (
    <div
      onClick={onClick}
      className="flex w-[46%] cursor-pointer flex-col rounded-md border shadow transition-all duration-300 hover:scale-105 hover:border-brandSecondary hover:border-opacity-50"
    >
      <div className="flex flex-row items-center gap-2 rounded-t border-b bg-gray-100 p-2">
        <p className="text-sm font-semibold">{title}</p>
        {icon}
      </div>

      <div className="p-4">
        <p className="text-xs">{description}</p>
        <div className="mt-4 flex w-full text-xs text-gray-400">Not Started</div>
      </div>
    </div>
  );
};

const Guides = ({ userId }: { userId: string }) => {
  const [guides, setGuides] = useState<Guide[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode | null>(null);

  const handleOpenModal = (contentValue: string) => {
    setModalContent(
      <GuideModal
        title={MODAL_CONTENT[contentValue].title}
        description={MODAL_CONTENT[contentValue].description}
        guideName={contentValue}
        userId={userId}
      />,
    );
    setModalOpen(true);
  };

  const { data: completedGuides } = useGetCompletedGuides(userId);

  useEffect(() => {
    if (completedGuides) {
      // Set guide state based on if the guide name is in completed guides dict
      const GUIDES_WITH_STATUS: Guide[] = GUIDES_WITHOUT_STATUS.map((guide) => ({
        ...guide,
        status: (completedGuides[guide.value] === 'completed' ? 'completed' : 'not-started') as
          | 'completed'
          | 'not-started',
      })).sort((a, _) => (a.status === 'completed' ? 1 : -1));

      setGuides(GUIDES_WITH_STATUS);
    }
  }, [completedGuides]);

  return (
    <>
      {guides.length > 0 && (
        <div className="flex w-full flex-col gap-2">
          <h2 className="mb-2 border border-x-0 border-t-0 px-4 pb-1 font-semibold">Guides</h2>
          <div className="flex w-full flex-wrap justify-between gap-4 px-4 pb-4">
            {guides.map((guide) => (
              <GuideItem
                key={guide.title}
                title={guide.title}
                description={guide.description}
                icon={guide.icon}
                onClick={() => {
                  trackCustomEvent(`Click Guide on homepage: ${guide.title}`);
                  handleOpenModal(guide.value);
                }}
                status={guide.status as 'completed' | 'not-started'}
              />
            ))}
          </div>

          <CustomModal isOpen={modalOpen} handleClose={() => setModalOpen(false)} content={modalContent} />
        </div>
      )}
    </>
  );
};

export default Guides;
