import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation } from 'react-query';

const BATCH_SIZE = 500;

const useToggleAll = () => {
  const { getFetchConfig } = useGetFetchConfig();

  // Define the mutation
  const putIncludedToggle = useMutation(
    async ({
      caseId,
      toggleValue,
      filterParams,
    }: {
      caseId: string;
      toggleValue: boolean;
      filterParams: { [key: string]: string[] };
    }) => {
      const fetchConfig = getFetchConfig({
        method: 'PATCH',
      });

      // Send the event ids in batches
      const sendBatch = async (batchFilterParams: { [key: string]: string[] }) => {
        const batchFetchConfig = {
          ...fetchConfig,
          body: JSON.stringify({ included: toggleValue, caseId, filters: batchFilterParams }),
        };

        const response = await fetch(`${APIBaseChronos}/api/fact/toggle-all-included`, batchFetchConfig);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      };

      if (filterParams.event_id && filterParams.event_id.length > BATCH_SIZE) {
        const batches = [];
        for (let i = 0; i < filterParams.event_id.length; i += BATCH_SIZE) {
          const batchFilterParams = { ...filterParams, event_id: filterParams.event_id.slice(i, i + BATCH_SIZE) };
          batches.push(sendBatch(batchFilterParams));
        }
        return Promise.all(batches);
      } else {
        return sendBatch(filterParams);
      }
    },
  );

  return putIncludedToggle;
};

export default useToggleAll;
