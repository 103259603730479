import { useEffect, useRef, useState } from 'react';

import { faComment } from '@fortawesome/free-regular-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackCustomEvent } from 'analytics/Mixpanel';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { renderHighlightedTextWithLinks } from 'helpers/textRendering';
import moment from 'moment';
import { Mention, MentionsInput } from 'react-mentions';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';

const TooltipStylesComments = {
  display: 'flex',
  width: '300px',
  padding: '16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '4px',
  background: '#FFFFFF',
  boxShadow: '0px 12px 20px 0px rgba(0, 0, 0, 0.05)',
  color: 'var(--colors-primary-slate-600, #455468)',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '-0.3px',
  zIndex: '100',
};

// Interfaces
interface TextInputCellProps {
  thread_id: string;
  comments_count: number;
  currentUserId: string;
}

interface Comment {
  comment_by_email: string;
  comment_by: string;
  comment_date: Date;
  comment: string;
  comment_id: string;
}

interface CommentsResponse {
  comments: Comment[];
}
interface User {
  id: string;
  display: string;
}

interface UserResponse {
  users: Array<{
    userId: string;
    email: string;
  }>;
}

const CommentPopup = ({ thread_id, comments_count, currentUserId }: TextInputCellProps) => {
  // State
  const [value, setValue] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const [commentCount, setCommentCount] = useState<number>(comments_count);
  const [loadingNewComment, setLoadingNewComment] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState<string | null>(null);

  const buttonRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef(null);

  // Data fetching
  const { getFetchConfig, fetchConfigGET } = useGetFetchConfig();
  const { data: userData } = useQuery<User[], Error>(
    'org-users',
    () =>
      fetch(`${APIBaseChronos}/api/fact/${thread_id}/users-with-access`, fetchConfigGET).then((res) => {
        if (!res.ok) throw new Error('Network response was not ok');
        return res.json().then((data: UserResponse) =>
          data.users.map((user) => ({
            id: user.userId,
            display: user.email,
          })),
        );
      }),
    {
      enabled: isOpen, // Enable user query only when the tooltip is open
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: comments,
    isLoading,
    isError,
    refetch,
  } = useQuery<CommentsResponse, Error>(
    ['comments', thread_id], // Include thread_id in the query key to ensure unique caching per thread
    () =>
      fetch(`${APIBaseChronos}/api/fact/${thread_id}/comments`, fetchConfigGET).then((res) => {
        if (!res.ok) throw new Error('Network response was not ok');
        return res.json();
      }),
    {
      refetchOnWindowFocus: false,
      enabled: isOpen, // This line controls the execution of the query based on the isOpen state.
    },
  );

  // Comment operations
  const { mutate } = useMutation(
    (newComment: { comment: string }) => {
      setLoadingNewComment(true);
      const fetchConfig = getFetchConfig({
        method: 'POST',
        data: {
          comment: newComment.comment,
          url: window.location.href,
        },
      });
      return fetch(`${APIBaseChronos}/api/fact/${thread_id}/comments`, fetchConfig).then((res) => res.json());
    },
    {
      onMutate: () => {
        const toastId = toast.loading('Adding comment...');
        return { toastId };
      },
      onSuccess: (data, variables, context) => {
        setLoadingNewComment(false);
        refetch();
        setValue('');
        setCommentCount(commentCount + 1);
        toast.update(context?.toastId ?? toast.loading(''), {
          render: 'Comment added successfully',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      },
      onError: (error, variables, context) => {
        setLoadingNewComment(false);
        console.error('Error posting comment:', error);
        toast.update(context?.toastId ?? toast.loading(''), {
          render: 'Failed to post comment. Please try again.',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
      },
    },
  );

  const { mutate: editComment } = useMutation(
    (updatedComment: { comment: string }) => {
      const fetchConfig = getFetchConfig({
        method: 'PATCH',
        data: {
          comment: updatedComment.comment,
        },
      });
      return fetch(`${APIBaseChronos}/api/comments/${editingCommentId}`, fetchConfig).then((res) => res.json());
    },
    {
      onMutate: () => {
        const toastId = toast.loading('Updating comment...');
        return { toastId };
      },
      onSuccess: (data, variables, context) => {
        refetch();
        setValue('');
        setEditingCommentId(null);
        toast.update(context?.toastId ?? toast.loading(''), {
          render: 'Comment updated successfully',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      },
      onError: (error, variables, context) => {
        console.error('Error updating comment:', error);
        toast.update(context?.toastId ?? toast.loading(''), {
          render: 'Failed to update comment. Please try again.',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
      },
    },
  );

  const { mutate: deleteComment } = useMutation(
    (commentId: string) => {
      const fetchConfig = getFetchConfig({
        method: 'DELETE',
      });
      return fetch(`${APIBaseChronos}/api/comments/${commentId}`, fetchConfig).then((res) => res.json());
    },
    {
      onMutate: () => {
        const toastId = toast.loading('Deleting comment...');
        return { toastId };
      },
      onSuccess: (data, variables, context) => {
        refetch();
        setCommentCount(commentCount - 1);
        toast.update(context?.toastId ?? toast.loading(''), {
          render: 'Comment deleted successfully',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      },
      onError: (error, variables, context) => {
        console.error('Error deleting comment:', error);
        toast.update(context?.toastId ?? toast.loading(''), {
          render: 'Failed to delete comment. Please try again.',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
      },
    },
  );

  // Handlers
  const handleEditComment = (comment: Comment) => {
    if (comment.comment_by !== currentUserId) return; // Ensure only the user who created the comment can edit it
    setValue(comment.comment);
    setEditingCommentId(comment.comment_id);
    setIsOpen(true);
  };

  const handleSubmitCommentUpdate = () => {
    editComment({ comment: value });
  };

  const handleCancelUpdate = () => {
    setValue('');
    setEditingCommentId('');
  };

  const handleDeleteComment = (comment: Comment) => {
    if (comment.comment_by !== currentUserId) return; // Ensure only the user who created the comment can delete it
    deleteComment(comment.comment_id);
  };

  const handleSubmitComment = () => {
    trackCustomEvent('Comment added');
    if (!value) return;
    mutate({ comment: value });
  };

  // Effects
  useEffect(() => {
    setCommentCount(comments_count);
  }, [comments_count]);

  return (
    <div className="flex flex-row items-center justify-start text-xs font-medium">
      <div
        data-tooltip-id={`comment-thread-${thread_id}`}
        ref={buttonRef}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className="relative flex h-full items-center gap-2 rounded border bg-gray-50 px-2 py-2 text-blue-500 shadow hover:cursor-pointer hover:bg-gray-100 hover:shadow-none"
      >
        {comments_count ? `(${comments_count})` : comments_count}
        <FontAwesomeIcon icon={faComment} className="h-4 w-4" />
      </div>

      <Tooltip
        id={`comment-thread-${thread_id}`}
        style={{ ...TooltipStylesComments }}
        place={'left'}
        isOpen={isOpen}
        opacity={1}
        clickable
      >
        <div ref={tooltipRef} className="w-full">
          {isLoading ? (
            <div>Loading comments...</div>
          ) : isError ? (
            <div>Error loading comments.</div>
          ) : (
            <div className="flex w-full flex-col items-start justify-center">
              <div className="max-h-96 w-full overflow-y-scroll">
                {!!comments?.comments?.length &&
                  [...comments?.comments].reverse()?.map((comment) => {
                    return (
                      <div className="mb-2 flex flex-col rounded border border-gray-100 p-2 shadow-sm">
                        <div className="text-sm font-semibold">{comment.comment_by_email}</div>
                        <div className="mb-1 text-xs text-gray-400">{`${moment(
                          comment.comment_date || Date.now(),
                        ).format('MMMM DD, YYYY')}`}</div>
                        {editingCommentId !== comment.comment_id ? (
                          <div className="text-gray-800">{renderHighlightedTextWithLinks(comment.comment)}</div>
                        ) : (
                          <MentionsInput
                            value={value}
                            onChange={(e: any) => setValue(e.target.value)}
                            className="h-12 w-full rounded border p-2"
                            placeholder={"Mention people using '@'"}
                            a11ySuggestionsListLabel={'Suggested mentions'}
                          >
                            <Mention
                              data={userData || []}
                              trigger="@"
                              style={{
                                backgroundColor: '#EEEEEE',
                              }}
                            />
                          </MentionsInput>
                        )}
                        {comment.comment_by === currentUserId && (
                          <div className="flex flex-row">
                            {!editingCommentId && (
                              <>
                                <button className="mr-2 text-blue-500" onClick={() => handleEditComment(comment)}>
                                  Edit
                                </button>
                                <button className="text-red-500" onClick={() => handleDeleteComment(comment)}>
                                  Delete
                                </button>
                              </>
                            )}
                            {editingCommentId === comment.comment_id && (
                              <>
                                <button className="mr-2 text-blue-500" onClick={handleSubmitCommentUpdate}>
                                  Update
                                </button>
                                <button className="text-red-500" onClick={handleCancelUpdate}>
                                  Cancel
                                </button>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
              {!editingCommentId && (
                <>
                  <MentionsInput
                    value={value}
                    onChange={(e: any) => setValue(e.target.value)}
                    className="h-12 w-full rounded border p-2"
                    placeholder={"Mention people using '@'"}
                    a11ySuggestionsListLabel={'Suggested mentions'}
                  >
                    <Mention
                      data={userData || []}
                      trigger="@"
                      style={{
                        backgroundColor: '#EEEEEE',
                      }}
                    />
                  </MentionsInput>
                  <div className="mb-2 mt-4 flex w-full flex-row justify-between">
                    <button
                      className="flex cursor-pointer items-center justify-center rounded bg-buttonPrimary px-2 py-1 font-bold not-italic text-white hover:bg-buttonPrimary-hover disabled:cursor-not-allowed"
                      onClick={handleSubmitComment}
                    >
                      {loadingNewComment ? <FontAwesomeIcon icon={faCircleNotch} className="fa-spin mr-1" /> : null}
                      Comment
                    </button>
                    <button
                      className="flex cursor-pointer items-center justify-center rounded border border-red-400 px-2 py-1 font-bold not-italic text-red-400 hover:bg-red-100 disabled:cursor-not-allowed"
                      onClick={() => setIsOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default CommentPopup;
