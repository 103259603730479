import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { ChronosFact } from 'types';

interface ChronosFactResponse {
  facts: ChronosFact[];
}

const useFetchDocumentFacts = ({
  docId,
  caseId,
}: {
  docId: string | null | undefined;
  caseId: string | null | undefined;
}) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<ChronosFactResponse, Error>(
    [QueryEntity.CaseEvent, { docId }],
    async () => {
      const response = await fetch(`${APIBaseChronos}/api/case/${caseId}/doc-facts?docId=${docId}`, fetchConfigGET);
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    },
    { refetchOnWindowFocus: false },
  );
};

export default useFetchDocumentFacts;
