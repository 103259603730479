import { useEffect, useState } from 'react';

import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { UseFetchUserData, User } from 'types';

const useFetchUserData = (accessToken: string | null): UseFetchUserData => {
  const [user, setUser] = useState<User | null>(null);
  const { fetchConfigGET } = useGetFetchConfig();

  useEffect(() => {
    const getUserData = async () => {
      if (!accessToken) return;

      try {
        const response = await fetch(`${APIBaseChronos}/api/user`, fetchConfigGET);
        const data: User = await response.json();
        setUser(data);
      } catch (error) {
        console.error('User data fetch error:', error);
      }
    };

    getUserData();
  }, [accessToken, fetchConfigGET]);

  return { user };
};

export default useFetchUserData;
