import { useState, useEffect, useCallback } from 'react';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackCustomEvent } from 'analytics/Mixpanel';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PlanStage } from 'types';

import Answer from '../Answer';
import { processQuestion } from '../helpers';
import { useEventSource } from './hooks/useEventSource';
import useDeleteThread from '../hooks/useDeleteThread';
import useGetMessages from '../hooks/useGetMessages';
import useGetThreadStatus from '../hooks/useGetThreadStatus';
import useProcessPlan from '../hooks/useProcessPlan';
import Processing from '../Processing';
import { ExecuteKimSearch, KimMode, ThreadStatus } from '../types';

interface ThreadViewProps {
  threadId: string;
  caseId: string;
  isExecutingSearch: boolean;
  errorToast: () => void;
  executeSearch: ExecuteKimSearch;
  question: string;
  // Threads
  goToThread: (threadId: string) => void;
  handleNewThread: () => void;
}

const ThreadView = ({
  threadId,
  caseId,
  isExecutingSearch,
  errorToast,
  executeSearch,
  question,
  // Threads
  goToThread,
  handleNewThread,
}: ThreadViewProps) => {
  const [threadStatus, setThreadStatus] = useState<ThreadStatus>('not-started');
  const queryClient = useQueryClient();

  // Plan state
  const [planOpen, setPlanOpen] = useState(false);
  const [loadingPlan, setLoadingPlan] = useState(false);
  const [planStage, setPlanStage] = useState<Record<string, string>>({});
  const [plan, setPlan] = useState<PlanStage[]>([]);
  const [planEditable, setPlanEditable] = useState(false);
  const [questionMode, setQuestionMode] = useState<KimMode>('question_flow');

  // Execution
  const [planExecuting, setPlanExecuting] = useState(false);
  const [executionMessages, setExecutionMessages] = useState<string[]>([]);

  const [processedQuestion, setProcessedQuestion] = useState('');

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const resetState = useCallback(() => {
    setPlanOpen(false);
    setLoadingPlan(false);
    setPlanStage({});
    setPlan([]);
    setPlanEditable(false);
    setExecutionMessages([]);
    setProcessedQuestion('');

    searchParams.delete('selectedDocId');
  }, [searchParams]);

  const handleError = useCallback(() => {
    errorToast();
    resetState();
    searchParams.delete('threadId');
    navigate(`${location.pathname}?${searchParams.toString()}`);
  }, [errorToast, resetState, searchParams, navigate, location.pathname]);

  // Event source
  const { eventSourceRef, setEventSourceRef, isMounted } = useEventSource();

  const { fetchConfigGET } = useGetFetchConfig();

  // Data fetching
  const { isLoadingMessages, responseMessages, refetchMessages } = useGetMessages(caseId || '', threadId || '');
  const { refetch: refetchThreadStatus } = useGetThreadStatus(caseId || '', threadId || '');

  // Mutations
  const { mutate: processPlan } = useProcessPlan();
  const { mutate: deleteThread } = useDeleteThread(caseId || '');

  // Execution State Helper Functions ----------------------------------------
  // Function for when plan is loading
  const pollForStatusChange = useCallback(async () => {
    const startTime = Date.now();

    return new Promise<void>((resolve) => {
      // Keep checking the thread status to see if it changes
      const pollInterval = setInterval(async () => {
        const response = await refetchThreadStatus();
        const currentStatus: ThreadStatus = response.data?.thread?.[0]?.status;

        if (currentStatus !== 'plan-loading' || Date.now() - startTime > 10000) {
          clearInterval(pollInterval);
          resolve();
          // This triggers the useEffect which handles the plan state
          setThreadStatus(currentStatus);
        }
      }, 1000);
    });
  }, [refetchThreadStatus]);

  // Function for when plan is loaded
  const handlePlanLoaded = useCallback(
    (internalMessage: any, userMessage: any) => {
      if (internalMessage) {
        setPlanOpen(true);

        const plan = internalMessage.message_info;
        if (plan && Object.keys(plan).length > 0) {
          setPlan(plan);

          // Go through the plan and set the plan stage to not-started, unless the plan stage is already complete
          const updatedPlanStages = plan.reduce((acc: Record<string, string>, stage: PlanStage) => {
            acc[stage.name] = stage.status || 'not-started';
            return acc;
          }, {});
          setPlanStage(updatedPlanStages);
          setLoadingPlan(false);
        } else {
          console.error('Error loading plan');
          handleError();
          return;
        }
        // Save the mode and question from the messages
        setQuestionMode(internalMessage.flow_type);
        setProcessedQuestion(userMessage.message_content);
      }
    },
    [handleError],
  );

  const handleExecutionMonitoring = useCallback(
    (threadId: string, messageId: string) => {
      if (threadId && messageId) {
        const currentEventSource = eventSourceRef;
        if (currentEventSource?.url?.includes(`${threadId}/poll/${messageId}`)) {
          return;
        }

        const createEventSource = () => {
          let retryCount = 0;
          const maxRetries = 3;

          if (currentEventSource) {
            currentEventSource.close();
          }

          // --------------------SSE---------------------
          // Send the messageId to poll endpoint
          const eventSource = new EventSourcePolyfill(
            `${APIBaseChronos}/api/case/${caseId}/thread/${threadId}/poll/${messageId}`,
            {
              headers: Object.fromEntries(fetchConfigGET.headers.entries()),
              heartbeatTimeout: 360000,
            },
          );
          setEventSourceRef(eventSource);

          eventSource.onmessage = (event: any) => {
            // Only process messages if component is still mounted

            if (!isMounted.current) {
              eventSource.close();
              return;
            }

            const message: { stage: string; type: string; message: string } = JSON.parse(event.data);

            if (message.type === 'error') {
              console.error('Error from sqs queue');
              searchParams.delete('selectedDocId');
              handleError();
              eventSource.close();
              return;
            }

            // Start message
            if (message.type === 'task_start') {
              setPlanStage((prevStages) => ({
                ...prevStages,
                [message.stage]: 'in-progress',
              }));
              setExecutionMessages((prevMessages) => [...prevMessages, message.message]);
            }

            // Progress message
            if (message.type === 'task_message') {
              setExecutionMessages((prevMessages) => [...prevMessages, message.message]);
              return;
            }

            // Complete message
            if (message.type === 'task_complete') {
              setPlanStage((prevStages) => {
                const updatedStages = {
                  ...prevStages,
                  [message.stage]: 'complete',
                };
                return updatedStages;
              });
            }

            // If all stages are completed, then close the event source
            if (message.type === 'all_complete') {
              setExecutionMessages((prevMessages) => [...prevMessages, 'Completing...']);
              eventSource.close();

              setTimeout(() => {
                // If a doc id is set, clear it
                refetchMessages();
                setThreadStatus('plan-complete');
                resetState();
              }, 500);
            }
          };

          eventSource.onerror = (error: any) => {
            if (!isMounted.current) {
              eventSource.close();
              return;
            }

            eventSource.close();

            // Check if it's a network error and we haven't exceeded max retries
            if (error.error?.message === 'network error' && retryCount < maxRetries) {
              retryCount++;
              console.log(`Retrying connection... Attempt ${retryCount} of ${maxRetries}`);

              // Retry after a delay (exponential backoff)
              setTimeout(
                () => {
                  createEventSource();
                },
                Math.min(1000 * Math.pow(2, retryCount), 10000),
              );
            } else {
              // If max retries exceeded or different error, show error toast
              console.error('EventSource error:', error);
              handleError();
              searchParams.delete('selectedDocId');
              navigate(`${location.pathname}?${searchParams.toString()}`);
            }
          };
        };
        createEventSource();
      } else {
        console.error('Error with threadId/messageId', threadId, messageId);
        handleError();
      }
    },
    [
      fetchConfigGET.headers,
      setEventSourceRef,
      isMounted,
      searchParams,
      handleError,
      resetState,
      refetchMessages,
      navigate,
      location.pathname,
      eventSourceRef,
      caseId,
    ],
  );

  // Function for when plan is executing
  const handlePlanExecuting = useCallback(
    (internalMessage: any, userMessage: any, threadId: string, messageId: string) => {
      setPlanEditable(false);
      // First load the plan in
      handlePlanLoaded(internalMessage, userMessage);
      setPlanExecuting(true);
      // Then setup connection to monitor the execution messages
      handleExecutionMonitoring(threadId, messageId);
    },
    [handlePlanLoaded, handleExecutionMonitoring],
  );
  // ------------------------------------------------------------------------

  // When this component mounts, use the threadId to check the status
  useEffect(() => {
    if (!threadId) return;

    const initialiseThread = async () => {
      // If we have come from a currently executing search then it
      // means the plan is loading so don't fetch the thread status
      if (isExecutingSearch) {
        setLoadingPlan(true);
        setPlanOpen(true);
        setThreadStatus('plan-loading');
        setProcessedQuestion(processQuestion(question));
        return;
      }

      // Use the thread id to get the status of the thread
      const threadResponse = await refetchThreadStatus();
      const currentStatus: ThreadStatus = threadResponse.data?.status;
      const activeMessageId = threadResponse.data?.active_message_id;

      // Get any messages that are on the thread
      const messagesResponse = await refetchMessages();

      // Check message response is ok
      if (!messagesResponse.data) {
        handleError();
        return;
      }

      const internalMessage = messagesResponse.data.find((message: any) => message.message_type === 'internal');
      const userMessage = messagesResponse.data.find((message: any) => message.message_type === 'user');

      // Depending on the thread status decide what to do
      switch (currentStatus) {
        // If the plan is loading then we just need to poll to wait until it is complete
        case 'plan-loading':
          setLoadingPlan(true);
          await pollForStatusChange();
          break;

        // If the plan is loaded then we need to load the plan in
        case 'plan-loaded':
          setPlanEditable(true);
          handlePlanLoaded(internalMessage, userMessage);
          break;

        // If the plan is executing then we need to establish the SQS polling to check for updates
        case 'plan-executing':
          handlePlanExecuting(internalMessage, userMessage, threadId, activeMessageId);
          break;

        // If the plan is complete then we need to just fetch all the messages on the thread to display  answer
        case 'plan-complete':
          refetchMessages();
          break;
      }
      setThreadStatus(currentStatus);
    };

    initialiseThread();
    // eslint-disable-next-line
  }, [threadId, isExecutingSearch]);

  // TODO: this is a hacky solution, best to remove question argument and allow FollowUpQuestions to setQuestionValue
  const executePlan = (threadId: string, currentPlan: PlanStage[], questionMode: KimMode, question?: string) => {
    trackCustomEvent('Kim Question Asked');

    setPlanExecuting(true);
    setExecutionMessages(['Starting plan execution...']);

    // Process the question (could use processQuestion here but maybe race condition if state hasn't updated yet)
    const processedQuestion = processQuestion(question || '');

    // Maybe when the user question is created we also need to save the tagged documents

    processPlan(
      {
        threadId: threadId,
        caseId: caseId || '',
        question: processedQuestion,
        mode: questionMode,
        plan: currentPlan,
      },
      {
        onSuccess: (data) => handleExecutionMonitoring(data.threadId, data.messageId),
        onError: (error) => {
          console.error('Error executing plan:', error);
          handleError();
        },
      },
    );
  };

  const cancelPlan = async () => {
    // Delete the thread id and all the messages on that thread
    await deleteThread(threadId || '');
    resetState();
    searchParams.delete('threadId');
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleBack = () => {
    searchParams.delete('threadId');
    queryClient.invalidateQueries({ queryKey: ['threads', caseId] });
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return (
    <>
      {
        // Check for !threadStatus in order to be backwards compatible
        threadStatus === 'plan-complete' || threadStatus === 'failed' || !threadStatus ? (
          <Answer
            caseId={caseId || ''}
            threadId={threadId || ''}
            messages={responseMessages}
            loading={isLoadingMessages}
            executeSearch={executeSearch}
            createNewThread={handleNewThread}
            goToThread={goToThread}
            handleBack={handleBack}
          />
        ) : threadStatus === 'plan-executing' || threadStatus === 'plan-loading' || threadStatus === 'plan-loaded' ? (
          <Processing
            planStage={planStage}
            planOpen={planOpen}
            plan={plan}
            setPlan={setPlan}
            planEditable={planEditable}
            executePlan={(plan: PlanStage[]) => executePlan(threadId || '', plan, questionMode, processedQuestion)}
            loadingPlan={loadingPlan}
            loading={planExecuting}
            messages={executionMessages}
            cancelPlan={cancelPlan}
            processedQuestion={processedQuestion}
            handleBack={handleBack}
          />
        ) : threadStatus === 'not-started' ? (
          <div className="flex h-full w-full items-center justify-center">
            <div className="h-full w-72 border-r border-gray-200"></div>
            <div className="flex w-full flex-col items-center">
              <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
            </div>
          </div>
        ) : (
          <></>
        )
      }
    </>
  );
};

export default ThreadView;
