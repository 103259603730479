import React, { useState } from 'react';

import { faCircleNotch, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HighlightArea } from '@react-pdf-viewer/highlight';
import useAddFact from 'api/mutations/useAddFact';
import Button from 'components/atoms/Button';
import { IMPORTANCY_OPTIONS, CLASSIFICATION_OPTIONS } from 'constants/chronos_table_options';
import { reactSelectStylesFormFact } from 'constants/styles';
import 'react-calendar/dist/Calendar.css';
import Select from 'react-select';
import { ChronosDoc, MyOptionType } from 'types';

interface FactAdderProps {
  docs: ChronosDoc[];
  handleNewFactCreated: () => void;
  docId?: string;
  fact?: string;
  sourceText?: string;
  highlightCoords?: HighlightArea[];
}

const FactAdder = ({ handleNewFactCreated, docs, docId, highlightCoords = [], sourceText = '' }: FactAdderProps) => {
  const [selectedDoc, setSelectedDoc] = useState<MyOptionType>(null);
  const [selectedImportancy, setSelectedImportancy] = useState<MyOptionType>(null);
  const [selectedClassification, setSelectedClassification] = useState<MyOptionType>(null);
  const [significance, setSignificance] = useState<string>('');
  const [actionDescribed, setActionDescribed] = useState<string>(sourceText);
  const [dateOfSubjectMatterText, setDateOfSubjectMatterText] = useState<string>('');

  const { isLoading: isLoadingAddNewFact, mutate } = useAddFact({
    docId: docId || selectedDoc?.value || null,
    onSuccess: handleNewFactCreated,
  });

  const handleChangeActionDescribed = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActionDescribed(e.target.value);
  };

  const handleAddFact = () => {
    if (selectedClassification && selectedImportancy) {
      mutate({
        classification: selectedClassification.value,
        importancy: selectedImportancy?.value,
        dateOfSubjectMatterText,
        actionDescribed,
        significance,
        highlightCoords,
      });
    }
  };

  const handleChangeDateOfSubjectMatterText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDateOfSubjectMatterText(e.target.value);
  };

  const handleChangeSignificance = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSignificance(e.target.value);
  };

  const docsOptions = docs.map((doc) => {
    return { value: doc.doc_id, label: doc.file_name };
  });

  const disabled = !selectedDoc && !docId;
  return (
    <>
      {isLoadingAddNewFact ? (
        <div className="flex h-96 flex-col items-center justify-center gap-8">
          <div className="text-xl">Adding Fact...</div>
          <FontAwesomeIcon icon={faCircleNotch} className="fa-spin text-4xl text-brandSecondary" />
        </div>
      ) : (
        <div className="flex flex-col px-6 py-4">
          <input
            onChange={handleChangeActionDescribed}
            className="mt-2 h-12 w-full rounded-lg bg-white pl-3 text-base font-normal not-italic text-black placeholder:text-gray-400 focus:outline-none"
            value={actionDescribed}
            placeholder="Fact"
            style={{ border: '1px solid #ECEFFF' }}
          ></input>
          {!docId && (
            <Select
              options={docsOptions}
              className="mt-3 w-full rounded-md bg-white outline-none"
              styles={reactSelectStylesFormFact}
              onChange={setSelectedDoc}
              value={selectedDoc}
              placeholder={'Select a document'}
            />
          )}
          <input
            onChange={handleChangeDateOfSubjectMatterText}
            className="mt-3 h-12 w-full rounded-lg bg-white pl-3 text-base font-normal not-italic text-black placeholder:text-gray-400 focus:outline-none"
            value={dateOfSubjectMatterText}
            placeholder="Date of fact"
            style={{ border: '1px solid #ECEFFF' }}
          />
          <input
            onChange={handleChangeSignificance}
            className="mt-3 h-12 w-full rounded-lg bg-white pl-3 text-base font-normal not-italic text-black placeholder:text-gray-400 focus:outline-none"
            value={significance}
            placeholder="Significance"
            style={{ border: '1px solid #ECEFFF' }}
          />
          <div className="mb-4 flex gap-2">
            <Select
              options={Object.values(IMPORTANCY_OPTIONS)}
              className="mt-3 w-full rounded-md bg-white outline-none"
              styles={reactSelectStylesFormFact}
              onChange={setSelectedImportancy}
              value={selectedImportancy}
              placeholder={'Relevant'}
            />
            <Select
              options={Object.values(CLASSIFICATION_OPTIONS)}
              className="mt-3 w-full rounded-md bg-white outline-none"
              styles={reactSelectStylesFormFact}
              onChange={setSelectedClassification}
              value={selectedClassification}
              placeholder={'Classification'}
            />
          </div>
          <Button
            onClick={handleAddFact}
            disabled={disabled}
            text="Add"
            type="primary"
            rounded="md"
            icon={<FontAwesomeIcon icon={faCirclePlus} className="mr-2" />}
          />
        </div>
      )}
    </>
  );
};

export default FactAdder;
