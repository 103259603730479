export interface ApiResponse {
  msg: string;
  type: string;
}

export interface Pagination {
  currentPage: number;
  filteredPages: number | null;
  pageSize: number;
  processedCount: number;
  totalCount: number;
  totalPages: number;
}

export enum QueryEntity {
  OrgUsageData,
  Case,
  Matter,
  CaseEvent,
  CaseEventCoordinates,
}
